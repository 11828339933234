import styled from 'styled-components';
import { DelayReasonsTable } from './DelayReasonsTable';
import ActionButton from '../../../../../components/ActionButton';
import { useWorkorderDelayReasons } from './hook/useWorkorderDelayReasons';
import ActionsTable from '../ActionsTab/ActionsTable';
import { useActions } from '../../../hooks/useActions';
import { useWODetailsModal } from '../../../hooks/useWODetailsModal';
import FormDialog from '../../../../../components/FormDialog';
import AddEditActionForm from '../../forms/AddEditActionForm';
import { Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PowerBiReport from '../../../../../components/PowerBiReport';
import * as R from 'ramda';

const StyledActionButton = styled(ActionButton)`
  margin-bottom: 10px;
`;

const StyledAccordion = styled(Accordion)`
  padding: 10px;
`;

export const TPlusOneTab = () => {
  const reportId = R.pathOr('', ['echoConfig', 'cwtTPlusOneReportId'], window);
  const reportPageName = R.pathOr('', ['echoConfig', 'cwtTPlusOnePageName'], window);

  const { handleAddDelayReason, canAddDelayReason } = useWorkorderDelayReasons();
  const {
    openAddEditActionDialog,
    setOpenAddEditActionDialog,
    reset,
    addEdit,
    handleSubmit,
    fetchActions,
    handleOpenAddActionDialog,
  } = useActions();
  const { tplus1, workorder } = useWODetailsModal();

  const cancelAddAction = () => {
    setOpenAddEditActionDialog(false);
    reset();
  };
  const handleSave = async () => {
    await handleSubmit();
    fetchActions();
  };
  return (
    <>
      <StyledAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Delay Reasons</AccordionSummary>
        <StyledActionButton
          color="primary"
          variant="text"
          onClick={() => handleAddDelayReason()}
          disabled={!canAddDelayReason}
        >
          Add Delay Reason
        </StyledActionButton>
        <DelayReasonsTable style={{ minHeight: 200, maxHeight: 500 }} />
      </StyledAccordion>
      <StyledAccordion style={{ marginBottom: 20 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>T+1 Actions</AccordionSummary>
        <StyledActionButton
          color="primary"
          variant="text"
          onClick={() => handleOpenAddActionDialog(tplus1)}
        >
          Add Action
        </StyledActionButton>
        <ActionsTable
          style={{ minHeight: 200 }}
          showOnlyTPlusOneActions
        />
        {openAddEditActionDialog && (
          <FormDialog
            title={addEdit === 'add' ? 'Add Action' : 'Edit Action'}
            onSave={handleSave}
            onCancel={cancelAddAction}
          >
            <AddEditActionForm />
          </FormDialog>
        )}
      </StyledAccordion>
      {reportId && workorder && (
        <PowerBiReport
          title="T+1"
          pageName={reportPageName}
          reportId={reportId}
          filter={`cwt_actions/workorder_id eq ${workorder.id}`}
          height="50vh"
          showActionBar
        />
      )}
    </>
  );
};
