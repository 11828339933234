import Page from '../../../../components/Page';
import PowerBiReport from '../../../../components/PowerBiReport';
import * as R from 'ramda';

const MetricsPage = () => {
  const reportId = R.pathOr('', ['echoConfig', 'cwtTPlusOneReportId'], window);
  const reportPageName = R.pathOr('', ['echoConfig', 'cwtTPlusOneMetricsPageName'], window);

  return (
    <Page title="Metrics">
      {reportId && (
        <PowerBiReport
          title="T+1"
          pageName={reportPageName}
          reportId={reportId}
          showActionBar
        />
      )}
    </Page>
  );
};
export default MetricsPage;
