import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import { BreadcrumbBroadcaster } from '../../context/breadcrumbs';

const LiveReportsPage = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/forecast`}>
        <Redirect to="/app/insights/forecast" />
      </Route>
      <Route path={`${path}/health-trends`}>
        <Redirect to="/app/insights/health-trends" />
      </Route>
      <Route path={`${path}/scorecard`}>
        <Redirect to={{ pathname: path, state: { initialTab: 'scorecard' } }} />
      </Route>
      <Route path={`${path}/documents`}>
        <Redirect to={{ pathname: path, state: { initialTab: 'attachments' } }} />
      </Route>
      <Route
        path={path}
        exact
      >
        <BreadcrumbBroadcaster pushCrumb="Live Report" />
        <LandingPage />
      </Route>
    </Switch>
  );
};

export default LiveReportsPage;
