import { Box, Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import TPlusOneWorkorderTable from './WorkorderTable';
import AutocompleteInput from '../../../../components/AutocompleteInput';
import { useMemo } from 'react';
import { useTPlusOne } from '../../hooks/useTPlusOne';
import {
  getCompWeekOptions,
  getLocationOptions,
  getPlanningCtrOptions,
  getPriorityOptions,
  getScheduleBacklogOptions,
  getSpecificationsOptions,
  getStatusOptions,
  getUnitOptions,
  getWorkGroupOptions,
  getWorkorderDelayReasonOptions,
  getWorkorderFlagOptions,
  getWorkorderOptions,
  getWorkTypeOptions,
} from '../../wo/components/utils';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { WODetailsModalContextProvider } from '../../hooks/useWODetailsModal';
import WODetailsModal from '../../components/WODetailsModal';
import { BookmarkMenu } from '../../bookmarks/BookmarkMenu';
import { FilterEntityType } from '../../bookmarks/hook/useBookmarks';
import isFeatureEnabled from '../../../../utils/feature-flags';
import DownloadButton from '../../../../components/DownloadButton';
import styled from 'styled-components';
import * as R from 'ramda';

const RefreshBacklogIcon = styled(AutorenewIcon)`
  cursor: pointer;
  padding-top: 0;
  color: ${({ theme }) => theme.colours.teals.teal1};
  width: 25px;
  height: 25px;
`;
const TPlusOneHeader = () => {
  const {
    workorders,
    setFilters,
    filters,
    woloading,
    getWorkorderById,
    selectedWorkorderId,
    filteredWorkorders,
    fetchWorkorders,
    exportCSV,
  } = useTPlusOne();

  return (
    <WODetailsModalContextProvider
      workorder={getWorkorderById(selectedWorkorderId)}
      tplus1
    >
      <Grid container>
        <Grid
          item
          xs={3}
        >
          <Box
            height="100px"
            minWidth={'120px'}
            borderRadius="5px"
            textAlign="center"
            boxShadow="rgb(191, 190, 190) 0px 2px 7px 0px;"
            marginRight="10px"
            padding="10px"
          >
            <Grid
              container
              alignItems="center"
              justifyContent="space-around"
            >
              <Grid
                item
                xs={5}
              >
                <Typography style={{ fontWeight: '500', fontSize: '35px' }}>
                  {woloading ? '...' : filteredWorkorders.length}
                </Typography>
                <Typography style={{ fontSize: '12px' }}>Work Orders</Typography>
              </Grid>

              <Grid
                item
                xs={5}
              >
                <Typography style={{ fontWeight: '500', fontSize: '35px' }}>
                  {woloading ? '...' : R.mean(filteredWorkorders.map(wo => wo.totallife)).toFixed(0)}
                </Typography>
                <Typography style={{ fontSize: '12px' }}>MTTR</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          spacing={2}
          xs={9}
        >
          <Grid
            item
            xs={4}
          >
            <AutocompleteInput
              label="Work Order"
              options={useMemo(() => getWorkorderOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, wonum: value });
              }}
              value={filters.wonum}
              multiple
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <AutocompleteInput
              label="Unit"
              options={useMemo(() => getUnitOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, unit: value });
              }}
              value={filters.unit}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <AutocompleteInput
              label="Priority"
              options={useMemo(() => getPriorityOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, wopriority: value });
              }}
              value={filters.wopriority}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <AutocompleteInput
              label="Work Type"
              options={useMemo(() => getWorkTypeOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, worktype: value });
              }}
              value={filters.worktype}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <AutocompleteInput
              label="Status"
              options={useMemo(() => getStatusOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, status: value });
              }}
              value={filters.status}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <AutocompleteInput
              label="Lead Crew"
              options={useMemo(() => getWorkGroupOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, crewworkgroup: value });
              }}
              value={filters.crewworkgroup}
              multiple
              selectAll
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          xs={12}
        >
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Schedule Backlog"
              options={useMemo(() => getScheduleBacklogOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, bpschedulebacklog: value });
              }}
              value={filters.bpschedulebacklog}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Planning Center"
              options={useMemo(() => getPlanningCtrOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, bpplanningctr: value });
              }}
              value={filters.bpplanningctr}
              multiple
              selectAll
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Specifications"
              options={useMemo(() => getSpecificationsOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, specifications: value });
              }}
              value={filters.specifications}
              multiple
              selectAll
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Location"
              options={useMemo(
                () => getLocationOptions(workorders).map(option => ({ ...option, label: option.label || '' })),
                [workorders]
              )}
              onChange={value => {
                setFilters({ ...filters, location: value });
              }}
              value={filters.location}
              multiple
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Flags"
              options={useMemo(() => getWorkorderFlagOptions(workorders), [workorders])}
              onChange={value => {
                setFilters({ ...filters, workorderFlags: value });
              }}
              value={filters.workorderFlags}
              multiple
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Comp Week"
              options={useMemo(() => getCompWeekOptions(workorders), [workorders])}
              value={filters.compweek}
              onChange={value => {
                setFilters({ ...filters, compweek: value });
              }}
              multiple
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <AutocompleteInput
              label="Delay Reasons"
              options={useMemo(() => getWorkorderDelayReasonOptions(workorders), [workorders])}
              value={filters.delayReasons}
              onChange={value => {
                setFilters({ ...filters, delayReasons: value });
              }}
              multiple
            />
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Box
              justifyContent={'space-between'}
              display="flex"
            >
              <TextField
                label="Min Life"
                value={filters.minLife}
                onChange={e => {
                  const minLifeValue = parseInt(e.target.value);
                  setFilters({ ...filters, minLife: isNaN(minLifeValue) ? [] : [minLifeValue] });
                }}
                style={{ width: '45%' }}
                type="number"
                error={filters.maxLife[0] != undefined && filters.minLife > filters.maxLife}
                helperText={filters.minLife[0] > filters.maxLife[0] ? 'Min Life must be less than Max Life' : ''}
              />
              <TextField
                label="Max Life"
                value={filters.maxLife}
                onChange={e => {
                  const maxLifeValue = parseInt(e.target.value);
                  setFilters({ ...filters, maxLife: isNaN(maxLifeValue) ? [] : [maxLifeValue] });
                }}
                style={{ width: '45%' }}
                type="number"
                error={filters.maxLife[0] != undefined && filters.minLife > filters.maxLife}
                helperText={filters.minLife[0] > filters.maxLife[0] ? 'Max Life must be greater than Min Life' : ''}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box
        width={'100%'}
        justifyContent={'space-between'}
        display="flex"
        marginTop={'15px'}
      >
        {isFeatureEnabled('cwtBookmarks') && (
          <BookmarkMenu
            type={FilterEntityType.TPlusOneWorkorderFilter}
            filter={filters}
            setFilters={setFilters}
          />
        )}
        <Box>
          <Tooltip
            title="Refresh Backlog"
            placement="right"
          >
            <IconButton onClick={fetchWorkorders}>
              <RefreshBacklogIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Export CSV"
            placement="top"
          >
            <DownloadButton onClick={exportCSV} />
          </Tooltip>
        </Box>
      </Box>
      <TPlusOneWorkorderTable />
      <WODetailsModal />
    </WODetailsModalContextProvider>
  );
};
export default TPlusOneHeader;
