import { Paper } from '@material-ui/core';
import { upperFirst } from 'lodash';
import React, { useState } from 'react';
import { forRoles } from '../../auth/roles';
import Page from '../../components/Page';
import Tab from '../../components/Tabs/Tab';
import TabPanel from '../../components/Tabs/TabPanel';
import Tabs from '../../components/Tabs/Tabs';
import DetailsTab from './components/DetailsTab';
import ForcedOutageReport from './components/ForcedOutageReport';
import ManagementTab from './components/ManagementTab';
import { TabState } from './constants';
import isFeatureEnabled from '../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../context/breadcrumbs';

const ForcedOutagesPage: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<TabState>('overview');
  const visibleTabs: TabState[] = ['overview', 'details', ...forRoles<TabState>(['Admin', 'Developer'], 'management')];
  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Forced Outages" />
      <Page
        title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Forced Outages'}
        bodyComponent={<Paper square />}
      >
        <Tabs
          value={currentTab}
          onChange={(_, value) => setCurrentTab(value)}
        >
          {visibleTabs.map(tabState => (
            <Tab
              key={tabState}
              value={tabState}
              label={upperFirst(tabState)}
            />
          ))}
        </Tabs>
        <TabPanel
          value="overview"
          currentTab={currentTab}
        >
          <ForcedOutageReport
            tab="overview"
            width="1591px"
            height="800px"
          />
        </TabPanel>
        <TabPanel
          value="details"
          currentTab={currentTab}
        >
          <DetailsTab />
        </TabPanel>
        {visibleTabs.includes('management') && (
          <TabPanel
            value="management"
            currentTab={currentTab}
          >
            <ManagementTab />
          </TabPanel>
        )}
      </Page>
    </>
  );
};

export default ForcedOutagesPage;
