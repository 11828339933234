import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import LandingPage from './pages/landing-page/LandingPage';
import ReportPreviewPage from './pages/report-preview/ReportPreviewPage';
import Page from '../../../components/Page';
import isFeatureEnabled from '../../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../../context/breadcrumbs';

const ReportReviewPage = ({ match }: any) => (
  <>
    <BreadcrumbBroadcaster pushCrumb="Report Review" />
    <Page
      title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Report Review'}
      bodyComponent={<Paper square />}
    >
      <Switch>
        <Route
          path={`${match.path}/`}
          exact
        >
          <LandingPage />
        </Route>
        <Route
          path={`${match.path}/:reportId`}
          exact
        >
          <ReportPreviewPage />
        </Route>
      </Switch>
    </Page>
  </>
);

export default ReportReviewPage;
