import Page from '../../../../components/Page';
import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useBusinessEquipmentRisk } from '../../../../context/business-equipment-risk-admin';
import Box from '@material-ui/core/Box';
import { USER_ROLES, UserWithRoles } from '../../../../types/risk-management';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import UserRiskRolesDialog from './UserRiskRolesDialog';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import isFeatureEnabled from '../../../../utils/feature-flags';

type UserListProps = {
  title: string;
  users: UserWithRoles[];
  onDelete: (id: string) => void;
  specialUser?: string | null;
  specialUserText?: string;
};

const UserList = ({ title, users, onDelete, specialUser, specialUserText }: UserListProps) => {
  return (
    <>
      <Typography style={{ fontWeight: 500, fontSize: '1rem' }}>{title}</Typography>
      <List dense={true}>
        {users.map(user => (
          <ListItem>
            <IconButton
              edge="start"
              aria-label="delete"
              onClick={() => onDelete(user.id)}
            >
              <DeleteIcon />
            </IconButton>
            <ListItemText
              primary={user.name}
              secondary={user.id === specialUser ? specialUserText : null}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

const BusinessEquipmentRiskAdmin = () => {
  const {
    nonApprovalUsers,
    nonEnvGroupUsers,
    submitUserRole,
    approvalUsers,
    envGroupUsers,
    removeUserRole,
    envGroupManager,
    mcrContactUsers,
    globalContactUsers,
    allUsers,
  } = useBusinessEquipmentRisk();
  const [openRiskApproverDialog, setOpenRiskApproverDialog] = useState<boolean>(false);
  const [openEnvGroupDialog, setOpenEnvGroupDialog] = useState<boolean>(false);
  const [openEnvGroupManagerDialog, setOpenEnvGroupManagerDialog] = useState<boolean>(false);
  const [openMcrContactDialog, setOpenMcrContactDialog] = useState<boolean>(false);
  const [openGlobalContactDialog, setOpenGlobalContactDialog] = useState<boolean>(false);

  const onInteract = useCallback(
    (role: USER_ROLES) => {
      if (role === USER_ROLES.APPROVER) {
        setOpenRiskApproverDialog(!openRiskApproverDialog);
      } else if (role === USER_ROLES.ENVIRONMENT_GROUP) {
        setOpenEnvGroupDialog(!openEnvGroupDialog);
      } else if (role === USER_ROLES.ENV_GROUP_MANAGER) {
        setOpenEnvGroupManagerDialog(!openEnvGroupManagerDialog);
      } else if (role === USER_ROLES.GLOBAL_CONTACT) {
        setOpenGlobalContactDialog(!openGlobalContactDialog);
      } else {
        setOpenMcrContactDialog(!openMcrContactDialog);
      }
    },
    [
      openRiskApproverDialog,
      openEnvGroupDialog,
      openMcrContactDialog,
      openEnvGroupManagerDialog,
      openGlobalContactDialog,
    ]
  );

  const onSubmit = useCallback(
    (userIds: string[], role: USER_ROLES) => {
      void submitUserRole(userIds, role);
      onInteract(role);
    },
    [onInteract]
  );

  return (
    <Box paddingTop="20px">
      <Page title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Business Equipment Risk Users'}>
        <Box paddingTop="20px">
          <Grid
            container
            spacing={5}
          >
            <Grid
              item
              xs={3}
            >
              <Box paddingBottom="20px">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Button
                      onClick={() => onInteract(USER_ROLES.APPROVER)}
                      variant="contained"
                    >
                      Add New Risk Approvers
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <UserList
                title="Risk Approval Users"
                users={approvalUsers}
                onDelete={(userId: string) => removeUserRole(userId, USER_ROLES.APPROVER)}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <Box paddingBottom="20px">
                <Button
                  onClick={() => onInteract(USER_ROLES.MCR_CONTACT)}
                  variant="contained"
                >
                  Add New MCR Contacts
                </Button>
              </Box>
              <UserList
                title="MCR Contact Users"
                users={mcrContactUsers}
                onDelete={(userId: string) => removeUserRole(userId, USER_ROLES.MCR_CONTACT)}
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <Box paddingBottom="20px">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Button
                      onClick={() => onInteract(USER_ROLES.ENVIRONMENT_GROUP)}
                      variant="contained"
                    >
                      Add New Env Group Users
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Button
                      onClick={() => onInteract(USER_ROLES.ENV_GROUP_MANAGER)}
                      variant="contained"
                    >
                      Choose Env Group Manager
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <UserList
                title="Risk Environmental Group Users"
                users={envGroupUsers}
                onDelete={(userId: string) => removeUserRole(userId, USER_ROLES.ENVIRONMENT_GROUP)}
                specialUser={envGroupManager?.id || null}
                specialUserText="Environment Group Manager"
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <Box paddingBottom="20px">
                <Button
                  onClick={() => onInteract(USER_ROLES.GLOBAL_CONTACT)}
                  variant="contained"
                >
                  Add New Global Contacts
                </Button>
              </Box>
              <UserList
                title="Global Contacts"
                users={globalContactUsers}
                onDelete={(userId: string) => removeUserRole(userId, USER_ROLES.GLOBAL_CONTACT)}
              />
            </Grid>
          </Grid>
        </Box>
        {openRiskApproverDialog && (
          <UserRiskRolesDialog
            users={nonApprovalUsers}
            onSubmit={users => {
              onSubmit(users, USER_ROLES.APPROVER);
            }}
            onClose={() => onInteract(USER_ROLES.APPROVER)}
            title="Add New Risk Approvers"
            multiple={true}
          />
        )}
        {openEnvGroupDialog && (
          <UserRiskRolesDialog
            users={nonEnvGroupUsers}
            onSubmit={users => onSubmit(users, USER_ROLES.ENVIRONMENT_GROUP)}
            onClose={() => onInteract(USER_ROLES.ENVIRONMENT_GROUP)}
            title="Add New Env Group Users"
            multiple={true}
          />
        )}
        {openEnvGroupManagerDialog && (
          <UserRiskRolesDialog
            users={envGroupUsers.map(user => ({ label: user.name, value: user.id }))}
            onSubmit={users => onSubmit(users, USER_ROLES.ENV_GROUP_MANAGER)}
            onClose={() => onInteract(USER_ROLES.ENV_GROUP_MANAGER)}
            title="Choose Env Group Manager"
            multiple={false}
            defaultValue={envGroupManager ? { label: envGroupManager.name, value: envGroupManager.id } : null}
          />
        )}
        {openGlobalContactDialog && (
          <UserRiskRolesDialog
            users={allUsers.map(user => ({ label: user.name, value: user.id }))}
            onSubmit={users => onSubmit(users, USER_ROLES.GLOBAL_CONTACT)}
            onClose={() => onInteract(USER_ROLES.GLOBAL_CONTACT)}
            title="Choose Global Contacts"
            multiple={true}
          />
        )}
        {openMcrContactDialog && (
          <UserRiskRolesDialog
            users={allUsers.map(user => ({ label: user.name, value: user.id }))}
            onSubmit={users => onSubmit(users, USER_ROLES.MCR_CONTACT)}
            onClose={() => onInteract(USER_ROLES.MCR_CONTACT)}
            title="Choose MCR Contacts"
            multiple={true}
          />
        )}
      </Page>
    </Box>
  );
};

export default BusinessEquipmentRiskAdmin;
