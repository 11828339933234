import type { RefWithRedirectTimeout, RedirectFactoryParams, RedirectParams } from './types';

const helpers = {
  genInstanceId: () => window.btoa(`${new Date().getTime()}`),
  timeoutRefClear: (redirectTimeout: RefWithRedirectTimeout) => {
    const { timeoutId = null } = redirectTimeout?.current || {};
    timeoutId && clearTimeout(timeoutId);
    redirectTimeout.current = redirectTimeout.current
      ? { ...redirectTimeout.current, timeoutId: null }
      : { eventEmitter: null, timeoutId: null };
  },
  redirectFactory:
    ({
      instanceId,
      navigationLock,
      targetPath,
      delayMs,
      redirectTimeout: redirectRef,
      setRedirectAction,
      redirect,
    }: RedirectFactoryParams) =>
    (params?: RedirectParams) => {
      const redirectTimeout = redirectRef as unknown as RefWithRedirectTimeout;
      const { eventEmitter: paramEventEmitter, targetPath: lateTargetPath } = params || {};
      // Intentionally do not resolve promise if clear-timeout called as we don't want any awaited actions after to fire as it was cancelled
      // don't want to accidentally trigger any further UI events if the redirect never happens.  This might need to be a parameter later
      helpers.timeoutRefClear(redirectTimeout);
      const { eventEmitter: oldEventEmitter = null } = redirectTimeout?.current || {};
      if (oldEventEmitter) {
        oldEventEmitter?.emit('cancel');
      }
      if (paramEventEmitter) {
        redirectTimeout.current = {
          ...redirectTimeout.current,
          timeoutId: redirectTimeout?.current?.timeoutId || null,
          eventEmitter: paramEventEmitter,
        };
      }
      return new Promise(resolve => {
        const timeoutId = setTimeout(
          () => {
            const { eventEmitter = null, timeoutId = null } = redirectTimeout?.current || {};
            /* istanbul ignore next */
            if (timeoutId === null) {
              // was cleared outside this hook - should be impossible to trigger callback after calling clearTimeout()
              eventEmitter?.emit('clear');
              resolve(undefined);
              return;
            }
            helpers.timeoutRefClear(redirectTimeout);
            // typescript complaining current could be undefiend... should be default anyways
            const { current: navLoc = { isLocked: false, instanceId } } = navigationLock;
            const redirectTo = {
              pathname: lateTargetPath || targetPath || '/app/dashboard',
              state: { resolvesRedirectInstanceId: instanceId },
            };
            // { to: redirectTo, state: { resolvesRedirectInstanceId: instanceId } }
            const stateUpdate = {
              resolve,
              instanceId,
              redirectTo,
              redirect: () => redirect(redirectTo),
            };
            const isInstanceMatched = navLoc.instanceId === instanceId;
            if (isInstanceMatched && !navLoc.isLocked) {
              setRedirectAction(stateUpdate);
              eventEmitter?.emit('redirect-hit');
              return;
            }
            eventEmitter?.emit('redirect-miss');
          },
          typeof delayMs !== 'number' || Number.isNaN(delayMs) ? 3000 : delayMs
        );

        redirectTimeout.current = {
          ...redirectTimeout.current,
          timeoutId,
          eventEmitter: paramEventEmitter,
        };
      });
    },
};
export default helpers;
