import React from 'react';
import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';

import Page from '../../../../components/Page';
import OutlineButton from '../../../../components/OutlineButton';
import OkayCancelDialog from '../../../../components/OkayCancelDialog';
import DashboardsTable from './DashboardsTable';
import DashboardDialog from './DashboardDialog';
import useDashboards from '../../hooks/dashboards';
import useNewDashboard from '../../hooks/new-dashboard';
import useEditDashboard from '../../hooks/edit-dashboard';
import useDeleteDashboard from '../../hooks/delete-dashboard';
import isFeatureEnabled from '../../../../utils/feature-flags';

const ActionBar = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 16px;
  justify-content: flex-end;

  border: ${({ theme }) => theme.borders.thin.solid1};
`;

const AdminDashboardsPage = () => {
  const { dashboards } = useDashboards();
  const { editingDashboard, editDashboardId, cancelEditDashboard, updateDashboard } = useEditDashboard(dashboards);
  const { newDashboard, addDashboard, cancelAddDashboard, createDashboard } = useNewDashboard();
  const { deletingDashboard, deleteDashboardId, cancelDeleteDashboard, deleteDashboard } =
    useDeleteDashboard(dashboards);

  return (
    <Page
      title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Admin - Dashboards'}
      bodyComponent={<Paper square />}
    >
      <ActionBar>
        <OutlineButton onClick={addDashboard}>Add Dashboard</OutlineButton>
      </ActionBar>
      <DashboardsTable
        dashboards={dashboards}
        onDelete={deleteDashboardId}
        onEdit={editDashboardId}
      />
      {newDashboard && (
        <DashboardDialog
          title="Add Dashboard"
          dashboard={newDashboard}
          onClose={cancelAddDashboard}
          onSave={createDashboard}
        />
      )}
      {editingDashboard && (
        <DashboardDialog
          title={`Edit Dashboard ID: ${editingDashboard.id}`}
          dashboard={editingDashboard}
          onClose={cancelEditDashboard}
          onSave={updateDashboard}
        />
      )}
      {deletingDashboard && (
        <OkayCancelDialog
          title="Delete?"
          okayLabel="Delete"
          onOkay={deleteDashboard}
          onCancel={cancelDeleteDashboard}
        >
          Are you sure you want to delete the dashboard "{deletingDashboard.title}"?
        </OkayCancelDialog>
      )}
    </Page>
  );
};

export default AdminDashboardsPage;
