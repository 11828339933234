import React from 'react';
import { useParams } from 'react-router-dom';
import useReport from '../../../hooks/report';
import Page from '../../../components/Page';
import ReportReview from '../../../components/ReportReview';
import { Downloader } from '../../../hooks/downloader';
import isFeatureEnabled from '../../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../../context/breadcrumbs';

const ReportReviewPage = () => {
  const { reportId } = useParams();
  const { report } = useReport(reportId);

  return (
    <>
      <BreadcrumbBroadcaster pushCrumb="Report Review" />
      <Page title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Report Review'}>
        {report && <ReportReview report={report} />}
      </Page>
    </>
  );
};

const RRPage = () => (
  <Downloader>
    <ReportReviewPage />
  </Downloader>
);

export default RRPage;
