import Page from '../../../../components/Page';
import Tabs from '../../../../components/Tabs/Tabs';
import OutagePlanTable from './components/outagePlans/OutagePlanTable';
import { useState } from 'react';
import { BusinessObjectivesTab, MSMProgramTab, OutagePlansTab } from './components/tabs';
import { Box } from '@material-ui/core';
import BusinessObjectivesTable from './components/businessObjectives/BusinessObjectivesTable';
import MSMProgramsTable from './components/MSMPrograms/MSMProgramsTable';
import isFeatureEnabled from '../../../../utils/feature-flags';

export default () => {
  const [tabValue, setTabValue] = useState(0);
  return (
    <Page title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Risk Management'}>
      <>
        <Tabs
          value={tabValue}
          onChange={(_, newValue: number) => setTabValue(newValue)}
        >
          {[
            <BusinessObjectivesTab
              currentTab={tabValue}
              key="business-objectives-tab"
            />,
            <MSMProgramTab
              currentTab={tabValue}
              key="msm-program-tab"
            />,
            <OutagePlansTab
              currentTab={tabValue}
              key="outage-plans-tab"
            />,
          ]}
        </Tabs>
        <Box sx={{ border: '1px solid #DAD8D8', borderRadius: '2px' }}>
          {tabValue === 0 && <BusinessObjectivesTable />}
          {tabValue === 1 && <MSMProgramsTable />}
          {tabValue === 2 && <OutagePlanTable />}
        </Box>
      </>
    </Page>
  );
};
