import Page from '../../../../components/Page';
import useReports from '../../../../hooks/reports';
import isFeatureEnabled from '../../../../utils/feature-flags';
import { RecentReportsList } from './RecentReportsList';
import getRecentReports from './hooks/get-recent-reports';

const RecentReportsPage = () => {
  const { reports, reverseApprove } = useReports(['approved'], true);

  const recentReports = getRecentReports(reports);

  return (
    <Page title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Admin - Recent Approved Reports'}>
      <div>
        <h3>Recent Approved Reports</h3>
      </div>
      <div>
        <RecentReportsList
          reports={recentReports}
          onDelete={reverseApprove}
        />
      </div>
    </Page>
  );
};

export default RecentReportsPage;
