import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import helpers from './helpers';
import type {
  RedirectAction,
  UseDeplayedRedirectParams,
  RedirectFactoryParams,
  RedirectTimeoutRef,
  NavigationLock,
} from './types';

const { timeoutRefClear, redirectFactory, genInstanceId } = helpers;
const useDelayedRedirect = (params?: UseDeplayedRedirectParams) => {
  const { targetPath, delayMs, isReplace } = params || {};
  const redirectTimeout = useRef<RedirectTimeoutRef>({ eventEmitter: null, timeoutId: null });
  const [redirectAction, setRedirectAction] = useState<RedirectAction | null>(null);
  const { replace, push, listen: historyListen } = useHistory();
  const location = useLocation<{ resolvesRedirectInstanceId?: string } | undefined>();
  const defaultInstanceId = genInstanceId();
  const navigationLock = useRef<NavigationLock>({
    instanceId: defaultInstanceId,
    isLocked: false,
  });
  const currentInstanceId = navigationLock?.current?.instanceId || defaultInstanceId;
  const clearTimeoutRef = useCallback(() => {
    const { eventEmitter } = redirectTimeout.current;
    timeoutRefClear(redirectTimeout);
    eventEmitter && eventEmitter?.emit('clear');
  }, []);
  const redirectFactoryOpts: Omit<RedirectFactoryParams, 'redirect'> = {
    instanceId: currentInstanceId,
    navigationLock,
    targetPath,
    delayMs,
    redirectTimeout,
    setRedirectAction,
  };
  const delayedPush = useCallback(redirectFactory({ ...redirectFactoryOpts, redirect: push }), [
    push,
    currentInstanceId,
    targetPath,
    delayMs,
    setRedirectAction,
  ]);
  const delayedReplace = useCallback(redirectFactory({ ...redirectFactoryOpts, redirect: replace }), [
    replace,
    currentInstanceId,
    targetPath,
    delayMs,
    setRedirectAction,
  ]);
  useEffect(() => {
    const unlisten = historyListen((_location, action) => {
      if (['POP', 'PUSH', 'REPLACE'].includes(action.toUpperCase())) {
        // if a redirect action is in flight we want cancel ours
        timeoutRefClear(redirectTimeout);
        navigationLock.current = { ...navigationLock.current, isLocked: true };
      }
    });
    return () => {
      // on unmount
      timeoutRefClear(redirectTimeout);
      unlisten();
      redirectTimeout.current.eventEmitter?.emit('unmount');
    };
  }, []);
  useEffect(() => {
    const prevInstanceId = navigationLock?.current?.instanceId;
    const locationState = location?.state || {};
    // always update the lock when location changes
    navigationLock.current = { instanceId: genInstanceId(), isLocked: false };
    // onMount do nothing - typescript says current may not exist?
    if (!prevInstanceId) {
      return;
    }
    // no state -> nothing todo
    if (!redirectAction) {
      return;
    }
    const { redirectTo, instanceId } = redirectAction;
    if (!prevInstanceId || !redirectTo || !instanceId) {
      return;
    }
    const isInstanceMatched = prevInstanceId === instanceId;
    // okay to call -> redirect factory already determined it was called safely and set target instance-id & state
    if (isInstanceMatched) {
      redirectAction.redirect();
      redirectTimeout.current.eventEmitter?.emit('redirecting');
      return;
    }
    const isLocationStateMatched = locationState.resolvesRedirectInstanceId === instanceId;
    // only resolve redirect if it was the last called & reached the targetted location
    if (isLocationStateMatched) {
      const newLocationState = { ...locationState };
      delete newLocationState.resolvesRedirectInstanceId;

      redirectAction.resolve();
      setRedirectAction(null);
      replace(location, Object.keys(newLocationState).length !== 0 ? newLocationState : null);

      redirectTimeout.current.eventEmitter?.emit('complete');
      return;
    }
  }, [replace, location, redirectAction, setRedirectAction]);
  // going forward please use the element to do redirects!
  const Element = useCallback(() => {
    if (redirectAction?.redirectTo) {
      redirectTimeout.current.eventEmitter?.emit('redirecting');
      return (
        <Redirect
          {...{
            to: redirectAction.redirectTo,
            ...(!isReplace ? { push: true } : {}),
          }}
        />
      );
    }
    return null;
  }, [redirectAction]);
  return {
    clear: clearTimeoutRef,
    redirect: isReplace ? delayedReplace : delayedPush,
    Element,
  };
};

export default useDelayedRedirect;
