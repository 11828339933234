import React from 'react';
import styled from 'styled-components';

import MUIPaper from '@material-ui/core/Paper';

import Page from '../../components/Page';
import UnstyledFeature from './Feature';
import isFeatureEnabled from '../../utils/feature-flags';
import { ReactComponent as UnstyledBlueLine } from '../../assets/coming-soon_blue-line.svg';
import { ReactComponent as UnstyledPaperPlane } from '../../assets/paper-airplane.svg';
import { BreadcrumbBroadcaster } from '../../context/breadcrumbs';

const Feature = styled(UnstyledFeature)``;

const BlueLine = styled(UnstyledBlueLine)``;

const Airplane = styled(UnstyledPaperPlane)``;

const FeatureListRow = styled.div`
  display: flex;

  ${Feature} {
    width: 352px;
  }

  ${Feature}:first-child {
    margin-right: 30px;
  }
`;

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: min(20%, 200px);

  ${FeatureListRow} {
    margin-bottom: 20px;
  }
`;

const Header = styled.h1`
  margin: 0px;
  color: ${({ theme }) => theme.colours.purples.purple1};
  font: ${({ theme }) => theme.fonts.medium20};
`;

const BodyText = styled.p`
  margin: 0px;
  font: ${({ theme }) => theme.fonts.normal14};
  color: ${({ theme }) => theme.colours.greys.darkGrey4};
`;

const HeaderContainer = styled.div`
  position: relative;

  padding: 104px 12px 16px 12px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${BlueLine} {
    position: absolute;
    top: 21px;
    right: 64px;
    @media only screen and (max-width: 942px) {
      display: none;
    }
  }

  ${Airplane} {
    position: absolute;
    top: 17px;
    right: 12px;
    @media only screen and (max-width: 942px) {
      display: none;
    }
  }

  ${Header} {
    margin-bottom: 4px;
  }
`;

const Paper = styled(MUIPaper)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);

  ${HeaderContainer} {
    margin-top: 147px;
    margin-left: 58px;
    width: 658px;
    height: 163px;
  }

  ${FeatureContainer} {
    margin-top: 26px;
  }
`;

const featureList = [
  {
    header: 'Follow a work order',
    features: ['Subscribe', 'Track status/updates'],
  },
  {
    header: 'Request new feature',
    features: ['Ability to request new features that align with your work flow'],
  },
  {
    header: 'Escalate an item',
    features: [
      'Auto-escalate for reminder, and manager review',
      'Escalate record for manager decision',
      'Escalate time sensitive or high risk (of not being completed) SHIP/CHIP',
    ],
  },
  {
    header: 'Vote for Feature',
    features: ['Up-vote requested features'],
  },
];

const featureRows = featureList.reduce(
  (acc, feature, index, parent) =>
    index % 2 === 0
      ? [
          ...acc,
          <FeatureListRow key={`${feature.header}-{index + 1 < parent.length ? '2' : '1'}`}>
            <Feature
              header={feature.header}
              features={feature.features}
            />
            {index + 1 < parent.length && (
              <Feature
                header={parent[index + 1].header}
                features={parent[index + 1].features}
              />
            )}
          </FeatureListRow>,
        ]
      : acc,
  []
);

const ComingSoonPage = () => (
  <>
    <BreadcrumbBroadcaster pushCrumb="Coming Soon" />
    <Page
      title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Coming Soon'}
      bodyComponent={<Paper square />}
    >
      <HeaderContainer>
        <BlueLine />
        <Airplane />
        <Header>Coming soon features</Header>
        <BodyText>Expect more features to make reporting even more efficient.</BodyText>
      </HeaderContainer>
      <FeatureContainer>{featureRows}</FeatureContainer>
    </Page>
  </>
);

export default ComingSoonPage;
