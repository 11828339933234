import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import UsersPage from './components/UsersPage';
import DashboardsPage from './components/DashboardsPage';
import DesignSystemPage from './components/DesignSystemPage';
import ElementsPage from './components/ElementsPage';
import MCRUnitsPage from './components/MCRUnitsPage';
import RecentReportsPage from './components/RecentReportsPage';
import { MCRUnitsProvider } from '../../hooks/mcr-units';
import AdminCategoriesPage from './components/AdminCategoriesPage';
import RiskManagementPage from './components/RiskManagementPage';
import OutagePlansProvider from '../../context/outage-plans';
import BusinessObjectivesProvider from '../../context/business-objective';
import MSMProgramsProvider from '../../context/msm-programs';
import BusinessEquipmentRiskAdmin from './components/BusinessEquipmentRisk';
import BusinessEquipmentRiskAdminProvider from '../../context/business-equipment-risk-admin';
import { isAllowedForRoles } from '../../auth/roles';
import { BreadcrumbBroadcaster } from '../../context/breadcrumbs';

const AdminPage = () => {
  const { path } = useRouteMatch();
  if (!isAllowedForRoles(['Admin'])) {
    return <Redirect to="/app/dashboard" />;
  }

  return (
    <Switch>
      <Route path={`${path}/users`}>
        <BreadcrumbBroadcaster pushCrumb="Admin - Users" />
        <UsersPage />
      </Route>
      <Route path={`${path}/dashboards`}>
        <BreadcrumbBroadcaster pushCrumb="Admin - Dashboards" />
        <DashboardsPage />
      </Route>
      <Route path={`${path}/design-system`}>
        <BreadcrumbBroadcaster pushCrumb="Design System" />
        <DesignSystemPage />
      </Route>
      <Route path={`${path}/elements`}>
        <BreadcrumbBroadcaster pushCrumb="Admin - Elements" />
        <ElementsPage />
      </Route>
      <Route path={`${path}/mcr-units`}>
        <MCRUnitsProvider>
          <BreadcrumbBroadcaster pushCrumb="Admin - MCR Units" />
          <MCRUnitsPage />
        </MCRUnitsProvider>
      </Route>
      <Route path={`${path}/recent-reports`}>
        <BreadcrumbBroadcaster pushCrumb="Admin - Recent Approved Reports" />
        <RecentReportsPage />
      </Route>
      <Route path={`${path}/admin-categories`}>
        <BreadcrumbBroadcaster pushCrumb="Admin - Admin Categories" />
        <AdminCategoriesPage />
      </Route>
      <Route path={`${path}/risk-management`}>
        <OutagePlansProvider>
          <BusinessObjectivesProvider>
            <MSMProgramsProvider>
              <BreadcrumbBroadcaster pushCrumb="Risk Management" />
              <RiskManagementPage />
            </MSMProgramsProvider>
          </BusinessObjectivesProvider>
        </OutagePlansProvider>
      </Route>
      <Route path={`${path}/business-equipment-risks`}>
        <BusinessEquipmentRiskAdminProvider>
          <BreadcrumbBroadcaster pushCrumb="Business Equipment Risk Users" />
          <BusinessEquipmentRiskAdmin />
        </BusinessEquipmentRiskAdminProvider>
      </Route>
      <Route>
        <Redirect to={`${path}/users`} />
      </Route>
    </Switch>
  );
};

export default AdminPage;
