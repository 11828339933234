import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import TPlusOnePage from './pages/TPlusOnePage';
import MetricsPage from './pages/MetricsPage';

const TPlusOne = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/wo`}>
        <TPlusOnePage />
      </Route>
      <Route path={`${path}/metrics`}>
        <MetricsPage />
      </Route>
      <Route
        exact
        path={path}
      >
        <Redirect to={`${path}/wo`} />
      </Route>
    </Switch>
  );
};
export default TPlusOne;
