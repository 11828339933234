import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

type BreadcrumbBroadcasterProps = {
  crumbDepth?: number;
  pushCrumb?: string | null | boolean | React.ReactNode;
  replaceCrumb?: string | null | boolean | React.ReactNode;
};
type PushSegment = {
  render: string | React.ReactNode;
};
type BaseSegment = {
  render: null | PushSegment['render'];
  depth?: number;
};
type ReplaceSegment = Required<{
  render: BaseSegment['render'];
  depth: BaseSegment['depth'];
}>;
type Segment = ReplaceSegment;
type RemoveBreadcrumbParams = { segment: BaseSegment; isPush?: boolean; isDestructive?: boolean };

export type ContextType = {
  current: Segment[];
  remove: (payload: RemoveBreadcrumbParams) => void;
  push: (segment: PushSegment) => void;
  replace: (segment: ReplaceSegment) => void;
};
export const List = styled.ul`
  display: inline-block;
  clear: both;
  &,
  & > li {
    padding: 0px;
    margin: 0px;
  }
  & > li {
    display: inline-block;
    float: left;
  }
`;
export const defaultContext = {
  current: [],
  remove: () => undefined,
  push: () => undefined,
  replace: () => undefined,
};
export const BreadcrumbsContext = createContext<ContextType>(defaultContext);
const BreadcrumbsProvider = ({ children }: React.PropsWithChildren) => {
  const [current, setCurrent] = useState<Segment[]>([]);
  const pushBreadcrumb = useCallback(
    (segment: PushSegment) => {
      setCurrent((prev: Segment[]) => [...prev, { ...segment, depth: prev.length } as Segment]);
    },
    [setCurrent]
  );
  const replaceBreadcrumb = useCallback(
    (segment: ReplaceSegment) => {
      setCurrent((prev: any) => {
        const arr = [...prev].splice(0, segment.depth);
        return [...arr, segment];
      });
    },
    [setCurrent]
  );
  const removeBreadcrumb = useCallback(
    ({ segment, isPush, isDestructive }: RemoveBreadcrumbParams) => {
      setCurrent((prev: Segment[]) => {
        const applyDepth = isPush ? prev.length - 1 : segment?.depth;
        const depth = typeof applyDepth === 'number' ? applyDepth : parseInt(applyDepth || '0', 10);
        const indexPos = prev.findIndex(s => segment.render === s.render && depth === s?.depth);
        if (indexPos === -1) {
          console.warn(`Could not find requested segment to remove at depth ${depth}`);
          return prev;
        }
        if (isDestructive) {
          return [...prev].splice(0, indexPos);
        }
        const beforeArr = [...prev].splice(0, indexPos);
        const afterArr = [...prev].splice(indexPos + 1, prev.length);
        return [...beforeArr, ...afterArr];
      });
    },
    [setCurrent]
  );
  const contextValue = {
    current,
    depthIndex: current.length,
    remove: removeBreadcrumb,
    push: pushBreadcrumb,
    replace: replaceBreadcrumb,
  };
  return <BreadcrumbsContext.Provider value={contextValue}>{children}</BreadcrumbsContext.Provider>;
};

export const useBreadcrumbsContext = () => {
  const context = useContext(BreadcrumbsContext);
  if (!context) {
    throw new Error('useBreadcrumbsContext must be used inside an BreadcrumbsContext');
  }
  return context;
};
export const BreadcrumbBroadcaster = (props: React.PropsWithChildren & BreadcrumbBroadcasterProps) => {
  const { crumbDepth, pushCrumb, replaceCrumb, children } = props;
  const { remove, push, replace } = useBreadcrumbsContext();
  const isPushCrumb = 'pushCrumb' in props;
  const isReplaceCrumb = 'replaceCrumb' in props;
  const isValidMutation = !(isPushCrumb && isReplaceCrumb);
  const renderForReplace = typeof replaceCrumb !== 'string' && 'children' in props ? children : replaceCrumb;
  const renderForPush = typeof pushCrumb !== 'string' && 'children' in props ? children : pushCrumb;
  useEffect(() => {
    const unmount = () => {
      if (!isValidMutation) {
        return;
      }
      if (isReplaceCrumb) {
        remove({ segment: { render: renderForReplace, depth: crumbDepth } });
      }
      if (isPushCrumb) {
        remove({ segment: { render: renderForPush }, isPush: true, isDestructive: true });
      }
    };
    if (!isValidMutation) {
      if (isPushCrumb && isReplaceCrumb) {
        console.warn(`Please only provide one prop 'pushCrumb' or 'replaceCrumb' not both`);
      }

      return unmount;
    }
    if (isReplaceCrumb) {
      replace({
        depth: crumbDepth,
        render: renderForReplace,
      });
    }
    if (isPushCrumb) {
      push({ render: renderForPush });
    }
    return unmount;
  }, [isValidMutation, isReplaceCrumb, isPushCrumb, replace, push, remove, renderForReplace, renderForPush]);

  return null;
};
export const Breadcrumbs = () => {
  const { current } = useBreadcrumbsContext();
  if (current.length === 0) {
    return null;
  }
  return (
    <List data-testId="breadcrumbs">
      {current
        .filter(({ render }) => !!render)
        .map(({ render, depth }) => (
          <li
            key={`depth-${depth}`}
            data-testId={`breadcrumbs-item-${depth}`}
          >
            {render}
          </li>
        ))}
    </List>
  );
};
export default BreadcrumbsProvider;
