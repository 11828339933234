import { Paper } from '@material-ui/core';
import { useState } from 'react';
import Page from '../../../../components/Page';
import useDeferralsRequests, { DeferralRequest, DeferralsRequestsProvider } from '../../../../hooks/deferral-requests';
import DeferralsHistoryTable from './components/DeferralsHistoryTable';
import RequestReopenDialog from './components/RequestReopenDialog';
import TopPanel from './components/TopFilterPanel';
import { Filter } from '../../../../types/deferral-types';
import isFeatureEnabled from '../../../../utils/feature-flags';

const DeferralsHistoryPage = () => {
  const [filters, setFilters] = useState({
    state: 'none',
    approver: 'none',
    element: 'none',
    facility: 'none',
  });
  const [deferralDialog, setOpenDeferralDialog] = useState<DeferralRequest | null>(null);
  const { getFilteredDeferrals, deleteDeferralRequest, resubmitDeferralRequest } = useDeferralsRequests();

  const handleReopenDeferral = (deferral: DeferralRequest) => {
    setOpenDeferralDialog(deferral);
  };

  return (
    <Page
      title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Deferral History Page'}
      bodyComponent={<Paper square />}
    >
      <TopPanel onChangeFilter={(filter: Filter) => setFilters({ ...filters, ...filter })} />
      <DeferralsHistoryTable
        deferrals={getFilteredDeferrals(filters)}
        onOpen={handleReopenDeferral}
        onClose={deleteDeferralRequest}
      />
      {deferralDialog && (
        <RequestReopenDialog
          request={deferralDialog}
          onSave={(resubmitDeferral: any) => {
            resubmitDeferralRequest(deferralDialog, resubmitDeferral);
            setOpenDeferralDialog(null);
          }}
          onCancel={() => {
            setOpenDeferralDialog(null);
          }}
        />
      )}
    </Page>
  );
};
export default () => (
  <DeferralsRequestsProvider>
    <DeferralsHistoryPage />
  </DeferralsRequestsProvider>
);
