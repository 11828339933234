import styled from 'styled-components';
import UnstyledPaper from '@material-ui/core/Paper';
import Page from '../../components/Page';
import { DashboardPageContextProvider } from './hooks/useDashboardPage';
import DashboardContent from './components/DashboardContent';
import isFeatureEnabled from '../../utils/feature-flags';
import { BreadcrumbBroadcaster } from '../../context/breadcrumbs';

const Paper = styled(UnstyledPaper)`
  display: flex;
  flex-direction: column;
`;

const DashboardPageInner = () => {
  return (
    <Page
      title={isFeatureEnabled('echoBreadcrumbsUi') ? undefined : 'Dashboard View'}
      bodyComponent={<Paper square />}
    >
      <DashboardPageContextProvider>
        <DashboardContent />
      </DashboardPageContextProvider>
    </Page>
  );
};

const DashboardPage = () => {
  if (isFeatureEnabled('echoBreadcrumbsUi')) {
    return (
      <>
        <BreadcrumbBroadcaster pushCrumb="Dashboard" />
        <DashboardPageInner />
      </>
    );
  }
  return <DashboardPageInner />;
};
export default DashboardPage;
